import React, { useContext } from 'react';
import { GlobalContext } from '../data/GlobalContext';
import Layout from '../../components/Layout';
import PagesHeader from '../../components/PagesHeader';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";

import CalltoActionOne from "../../components/call-to-action/CalltoActionOne";
import ValuesContent from "../../components/home/ValuesContent";


const ServicesDetalle = (props) => {
    const dbData = useContext(GlobalContext);

    const { id } = useParams();

    return (
        <>
            {
                dbData.dbServices.map((service, index) => {
                    if (service.name.replaceAll(" ", "-").toLowerCase() === id) {
                        return (
                            <Layout key={index} pageTitle={service.name}>
                                <PagesHeader
                                    title={service.name}
                                    crumbTitle={service.name}
                                    pageHeaderBg={
                                        service.description.length > 1 ?
                                            service.description[1].img
                                        :
                                        dbData.gallery[5]
                                    }
                                />
                                <section className="cause-details blog-details  pt-120 pb-40">
                                    <Container>
                                        <Row>
                                            <Col md={12} lg={8}>
                                                <div className="cause-details__content">
                                                    <div className="cause-card">
                                                        <div className="cause-card__inner">
                                                            <div className="cause-card__image">
                                                                <img src={`${service.description[0].img}`} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h3>{service.name}</h3>
                                                {
                                                    service.description.map((item, index) => {
                                                        return (
                                                            <p key={index}>{item.text}</p>
                                                        )
                                                    })
                                                }
                                                <div className="cause-card__bottom mt-5">
                                                    <Link className="scroll-to-target thm-btn-inverse text-capitalize" to="/contact">
                                                        free estimate
                                                    </Link>
                                                </div>
                                            </Col>
                                            <Col md={12} lg={4}>
                                                <div className="cause-details__sidebar">
                                                    <div className="cause-details__organizer">
                                                        <h4 className="cause-details__donations-title">Our Services</h4>
                                                        <ul>
                                                            {
                                                                dbData.dbServices.slice(0, 8).map((service, index) => {
                                                                    return (
                                                                        <li key={index}>
                                                                            <Link to={`${service.name.replaceAll(" ", "-").toLowerCase()}`}>
                                                                                {service.name}
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className="cause-details__organizer">
                                                        <h4 className="cause-details__donations-title">Contact</h4>
                                                        {
                                                            dbData.dbPrincipal.phones.map((item, index) => {
                                                                return (
                                                                    <div>
                                                                        <a key={index} href={`tel:+1${item.phone}`}>
                                                                            <i className="azino-icon-telephone"></i>
                                                                            <span className='mx-1'> {item.phone}</span>
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            dbData.dbPrincipal.emails.map((item, index) => {
                                                                return (
                                                                    <div>
                                                                        <a key={index} href={`mailto:${item.email}`}>
                                                                            <i className="azino-icon-email"></i>
                                                                            <span className='mx-1'> {item.email}</span>
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </section>
                                <CalltoActionOne />
                                <ValuesContent />

                            </Layout>
                        )
                    }
                })

            }

        </>


    )
}

export default ServicesDetalle;