import { useContext } from "react";
import { GlobalContext } from "../../components/data/GlobalContext";

import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

// import dbData from "../../components/data/db";

const imgUrl = require.context('../../assets/images', true);


const DirectorioSlider = ({ extraClass }) => {

    const dbData = useContext(GlobalContext);

    const brandCarouselOptions = {
        spaceBetween: 100,
        slidesPerView: 4,
        autoplay: { delay: 5000, disableOnInteraction: false },
        breakpoints: {
            0: {
                spaceBetween: 30,
                slidesPerView: 2
            },
            375: {
                spaceBetween: 30,
                slidesPerView: 2
            },
            575: {
                spaceBetween: 30,
                slidesPerView: 3
            },
            767: {
                spaceBetween: 50,
                slidesPerView: 3
            },
            991: {
                spaceBetween: 50,
                slidesPerView: 3
            },
            1199: {
                spaceBetween: 100,
                slidesPerView: 3
            }
        }
    };
    return (
        <Container className="pb-5">
            <div className={`client-carousel pt-60 pb-20  ${extraClass}`}>
                <h2 className="text-center text-capitalize pb-4">Follow us</h2>
                <Swiper {...brandCarouselOptions}>
                    <div className="swiper-wrapper">
                        {
                            dbData.dbSocialMedia.directorios && dbData.dbSocialMedia.directorios[0].name !== ' ' ?
                            dbData.dbSocialMedia.directorios.map((items, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <a
                                                href={items.url}
                                                target='_blank'
                                            >
                                                <img src={imgUrl(`./directorios/${items.image}`)} width='100%' alt={items.name} />
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                            : null
                        }
                    </div>
                </Swiper>
            </div>
        </Container>
    )
}

export default DirectorioSlider;