import { useContext, useState } from "react";
import { GlobalContext } from "../data/GlobalContext";
import { Link } from "react-router-dom";

// import dbMenu from "../data/dbMenu";




const NavsLink = ({ extraClassName }) => {

    const dbData = useContext(GlobalContext);

    const [subOpen, setSubOpen] = useState(false);
    const [subOpenItems, setSubOpenItems] = useState(false);

    const openSubMenu = () => setSubOpen(!subOpen);
    const openSubMenuItems = () => setSubOpenItems(!subOpenItems);

    const subItemServices = dbData.dbServices.map((service, index) => {
        return (
            {
                name: `${service.name}`,
                link: `/${service.name.replaceAll(" ", "-").toLowerCase()}`,
            }
        )
    })


    const dbMenu = {
        dbMenu: [
            {
                name: `Home`,
                link: `/`,
            },
            {
                name: `About`,
                link: `/about`,
            },
            {
                name: `Services`,
                link: `/services`,
                submenu: [
                    {
                        name: `Roofing`,
                        link: `/services`,
                        submenuitems: [
                            ...subItemServices
                        ],
                    },
                    {
                        name: `Painting`,
                        link: `/services`,
                        submenuitems: [
                            ...subItemServices
                        ],
                    },
                    {
                        name: `Other`,
                        link: `/services`,
                        submenuitems: [
                            ...subItemServices
                        ],
                    },
                ],
            },
            {
                name: `Gallery`,
                link: `/gallery`,
            },
            {
                name: `Contact`,
                link: `/contact`,
            },
        ],
    }

    const handleDropdownStatus = (e) => {
        let clickedItem = e.currentTarget.parentNode;
        clickedItem.querySelector(".dropdown-list").classList.toggle("show");
    };

    return (
        <ul className={`main-menu__list ${extraClassName}`}>
            {
                dbMenu.dbMenu.map((item, index) => {
                    return (
                        item.submenu ?
                            <li key={index} className='dropdown'>
                                <Link to={item.link}
                                >{item.name}</Link>
                                <button aria-label="dropdown toggler" onClick={openSubMenu}>
                                    <i className="fa fa-angle-down"></i>
                                </button>
                                <ul className={subOpen === true ? "dropdown show" : "dropdown"}>
                                    {
                                        item.submenu.map((subItem, index) => {
                                            const filterServices = dbData.dbServices.filter(element => element.subtitle === subItem.name.toLowerCase())
                                            return (
                                                subItem ?
                                                    <li key={index} className='dropdown toggler' onClick={openSubMenuItems}>
                                                        <Link
                                                            to={subItem.link}
                                                        >
                                                            {subItem.name}
                                                        </Link>
                                                        <button aria-label="dropdown toggler" onClick={openSubMenuItems}>
                                                            <i className="fa fa-angle-down"></i>
                                                        </button>
                                                        <ul className={subOpenItems === true ? "dropdown-list show" : "dropdown-list"}>
                                                            {
                                                                filterServices.map((fil, index) => {
                                                                    return (
                                                                        <li key={index}>
                                                                            <Link
                                                                                to={`/${fil.name.replaceAll(" ", "-").toLowerCase()}`}
                                                                            >
                                                                                {
                                                                                    fil.name
                                                                                }
                                                                            </Link>
                                                                        </li>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </li>
                                                    :
                                                    <li key={index}>
                                                        <Link
                                                            to={subItem.link}
                                                        >
                                                            {subItem.name}
                                                        </Link>
                                                    </li>
                                            )
                                        })
                                    }
                                </ul>
                            </li>
                            :
                            item.url ?
                                <li key={index}>
                                    <a href={item.url} target="_blank" rel="noreferrer">{item.name}</a>
                                </li>
                                :
                                <li key={index}>
                                    <Link to={item.link}>{item.name}</Link>
                                </li>
                    )
                })
            }
        </ul>

    );
}

export default NavsLink;