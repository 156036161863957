import { useContext } from "react";
import { GlobalContext } from "../components/data/GlobalContext";

import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import Layout from "../components/Layout";
import MainSlider from "../components/slider/MianSlider";
import CalltoActionOne from "../components/call-to-action/CalltoActionOne";
import ValuesContent from "../components/home/ValuesContent";
import CallToActionTwo from "../components/call-to-action/CallToActionTwo";
import HomeContentOne from "../components/home/HomeContentOne";
import DirectorioSlider from "../components/slider/DirectorioSlider";
import RedesContent from "../components/home/RedesContent";
import AboutTwo from "../components/about/AboutTwo";
import IconTitle from "../components/IconTitle";
import CardServicesTwo from "../components/services/CardServicesTwo";
import CallToActionThree from "../components/call-to-action/CallToActionThree";
import GoogleMap from "../components/GoogleMap";
import ModalForm from "../components/modal/ModalForm";
import PaletaColorContent from "../components/PaletaColorContent";

const imgUrl = require.context('../assets/images', true);


const Home = () => {
    const dbData = useContext(GlobalContext);
    return (
        <Layout pageTitle="Home">
            <ModalForm />
            <MainSlider />
            <CalltoActionOne />
            <ValuesContent />
            <CallToActionTwo title={dbData.dbSlogan[2].slogan} bgImages={dbData.gallery[22]} />
            <HomeContentOne />
            {
                dbData.gmb.link.length > 1 ?
                    <Container>

                        <div className="gmbContent">
                            <div>
                                <a
                                    href={dbData.gmb.link}
                                    target='_blank'
                                    rel="noreferrer"
                                >
                                    <img src={dbData.gmb.img} alt='GMB images' />
                                </a>
                            </div>
                        </div>
                    </Container>
                    : null
            }
            <DirectorioSlider extraClass="client-carousel__has-border-top" />
            <RedesContent />
            <AboutTwo images={dbData.gallery[33]} />
            <Container className="homeContent__services">
                <div className="block-title">
                    <IconTitle tagLine={`Welcome to ${dbData.dbPrincipal.name}`} />
                    <h3 className="servicesHome__title">our services</h3>
                    {/* <div className="text-center">
                        <Link to='/services' className="text-capitalize">
                            view all services
                        </Link>
                    </div> */}
                </div>
                <CardServicesTwo />
            </Container>
            <PaletaColorContent />
            <CallToActionThree />
            <GoogleMap extraClass="contact" />
        </Layout>
    );
}
export default Home;